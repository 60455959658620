var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-row align-center justify-space-between mb-4"},[_c('div',{staticClass:"top-header mb-0 text-uppercase"},[_c('v-icon',{staticClass:"cursor--pointer",on:{"click":_vm.backEmployees}},[_vm._v("mdi-reply")]),_vm._v(" "+_vm._s("Employees")+" ")],1)]),_c('v-card',{staticClass:"my-1"},[_c('v-toolbar',{staticClass:"primary",attrs:{"tag":"div","flat":"","dark":"","tile":""}},[_c('div',{staticClass:"text-h6"},[_vm._v("Employee Details")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"white","icon":""},on:{"click":_vm.gotoEmployee}},on),[_c('v-icon',[_vm._v("mdi-account-edit")])],1)]}}])},[_c('span',[_vm._v("Employee Edit")])])],1),(_vm.loading)?[_c('div',{staticClass:"text-center my-2"},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"primary","indeterminate":""}})],1)]:_vm._e(),(_vm.employee)?[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"text-caption font-weight-blod text--secondary"},[_vm._v(" Full name: ")]),_c('div',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.employee.fullname ? _vm.employee.fullname : "-")+" ")])]),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"text-caption font-weight-blod text--secondary"},[_vm._v(" Username: ")]),_c('div',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.employee.username ? _vm.employee.username : "-")+" ")])]),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"text-caption font-weight-blod text--secondary"},[_vm._v(" Email: ")]),_c('div',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.employee.email ? _vm.employee.email : "-")+" ")])])],1),_c('v-divider',{staticClass:"my-2"}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('div',{staticClass:"text-caption font-weight-blod text--secondary"},[_vm._v(" Phone: ")]),_c('div',{staticClass:"text-body-1"},[(_vm.employee.phone)?[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.makeCallV(_vm.employee.phone)}}},[_vm._v(" "+_vm._s(_vm.employee.phone)+" ")]),_c('div',{staticClass:"mx-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"info"},on:{"click":function($event){return _vm.copyPhone(_vm.employee.phone)}}},on),[_vm._v("mdi-content-copy")])]}}],null,false,4065141428)},[_c('span',[_vm._v("Copy Phone")])])],1)])]:[_vm._v("-")]],2)]),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('div',{staticClass:"text-caption font-weight-blod text--secondary"},[_vm._v(" Ext: ")]),_c('div',{staticClass:"text-body-1"},[(_vm.employee.ext)?[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.makeCallV(_vm.employee.ext)}}},[_vm._v(" "+_vm._s(_vm.employee.ext)+" ")]),_c('div',{staticClass:"mx-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"info"},on:{"click":function($event){return _vm.copyPhone(_vm.employee.ext)}}},on),[_vm._v("mdi-content-copy")])]}}],null,false,2977496097)},[_c('span',[_vm._v("Copy Phone")])])],1)])]:[_vm._v("-")]],2)]),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('div',{staticClass:"text-caption font-weight-blod text--secondary"},[_vm._v(" Rol: ")]),_c('div',{staticClass:"text-body-1",style:({ color: _vm.rolColor(_vm.employee.rol) })},[_vm._v(" "+_vm._s(_vm.employee.rol)+" ")])]),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('div',{staticClass:"text-caption font-weight-blod text--secondary"},[_vm._v(" Occupation: ")]),_c('div',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.employee.ocupation ? _vm.employee.ocupation.name : "-")+" ")])])],1),_c('v-divider',{staticClass:"my-2"}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"text-caption font-weight-blod text--secondary"},[_vm._v(" Create Date: ")]),_c('div',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.createDate)+" ")])]),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"text-caption font-weight-blod text--secondary"},[_vm._v(" Start Date: ")]),_c('div',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.startDate)+" ")])]),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"text-caption font-weight-blod text--secondary"},[_vm._v(" End Date: ")]),_c('div',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.endDate)+" ")])])],1),_c('v-divider',{staticClass:"mb-4"}),_c('div',{staticClass:"text-center"},[_vm._v("Companies")]),_c('v-divider',{staticClass:"mb-2"}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.employeeCompanies,"items-per-page":10,"item-key":"name","loading":_vm.loadingCompanies},scopedSlots:_vm._u([{key:"item.company",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.companyStatePrice ? item.companyStatePrice.company.name : "-")+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.companyStatePrice ? item.companyStatePrice.state.name : "-")+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.price))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.createdAt))+" ")]}}],null,true)})],1)]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }